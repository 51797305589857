<template>
  <BaseWrapper v-if="state === states.READY">
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <li
        v-for="exam in exams"
        :key="exam.slug"
        class="flex flex-col col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow"
      >
        <div class="flex items-center justify-between w-full p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center justify-between mb-6 space-x-3">
              <div class="flex space-x-2">
                <ExamIcon
                  v-if="exam.icon"
                  :icon="exam.icon"
                  class="w-6 h-6 mt-1"
                />
                <h3 class="text-xl font-medium leading-7 text-gray-900">
                  {{ exam.name }}
                </h3>
              </div>
              <div v-if="showTooltips && !hasPaygUnlockedExams && !hasAnySubscription && isPAYG">
                <span
                  v-if="exam.includeInFreePlan"
                  class="inline-flex flex-shrink-0 items-center justify-center px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full"
                >
                  Free
                </span>
                <TooltipButton
                  v-else-if="!exam.includeInFreePlan"
                  interactive
                >
                  <template v-slot:content>
                    <span
                      class="inline-flex flex-shrink-0 items-center justify-center px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded-full cursor-pointer w-20"
                    >
                      <Icon
                        view-box="0 0 24 24"
                        class="w-3 h-3"
                      >
                        <Lock />
                      </Icon>
                      <span class="ml-1">Locked</span>
                    </span>
                  </template>
                  <template v-slot:tooltip>
                    <router-link
                      :to="{ name: 'settings-payg-plan'}"
                      class="inline-flex items-center justify-center hover:text-gray-300"
                    >
                      <Icon
                        view-box="0 0 24 24"
                        class="w-3 h-3"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <Unlock />
                      </Icon>
                      <span class="ml-1">Upgrade to unlock</span>
                    </router-link>
                  </template>
                </TooltipButton>
              </div>
            </div>
            <p class="mt-1 text-sm text-gray-600">
              {{ exam.description }}
            </p>
            <div class="flex flex-shrink-0 mt-4 text-sm text-gray-600">
              <svg
                class="w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span
                v-if="exam.minutes"
                class="ml-2"
              >
                {{ exam.minutes }} mins
              </span>
              <span
                v-else-if="!exam.minutes"
                class="ml-2"
              >
                Not timed
              </span>
            </div>
          </div>
        </div>
        <div class="mt-auto">
          <div class="flex -mt-px divide-x divide-gray-200">
            <div class="flex flex-1 w-0">
              <a
                v-if="exam.description"
                href="javascript:;"
                class="relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium text-gray-700 border border-transparent rounded-bl-lg hover:text-gray-600"
                @click="openPreview(exam.slug)"
              >
                <svg
                  class="w-5 h-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                <span class="ml-2">More Details</span>
              </a>
              <span
                v-else
                class="relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium text-gray-400 border border-transparent rounded-bl-lg"
              >
                <svg
                  class="w-5 h-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                  />
                </svg>
                <span class="ml-2">More Details</span>
              </span>
            </div>
            <div
              v-if="useAddOption"
              class="flex flex-1 w-0 -ml-px"
            >
              <a
                v-if="canAddExam(exam)"
                href="javascript:;"
                class="relative inline-flex items-center justify-center flex-1 w-0 py-4 text-sm font-medium text-gray-700 border border-transparent rounded-br-lg hover:text-gray-600"
                @click="addExam(exam)"
              >
                <svg
                  class="w-5 h-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-2">Add</span>
              </a>
              <span
                v-else-if="!canAddExam(exam)"
                class="relative inline-flex items-center justify-center flex-1 w-0 py-4 text-sm font-medium text-gray-300 border border-transparent rounded-br-lg"
              >
                <svg
                  class="w-5 h-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-2">Add</span>
              </span>
            </div>
          </div>
        </div>
      </li>
      <li
        v-for="customExam in customExams"
        :key="customExam.slug"
        class="flex flex-col col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow"
      >
        <div class="flex items-center justify-between w-full p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center justify-between mb-6 space-x-3">
              <div class="flex space-x-2">
                <ExamIcon
                  icon="cog"
                  class="w-6 h-6 mt-0.5"
                />
                <h3 class="text-xl font-medium leading-7 text-gray-900">
                  {{ customExam.name }}
                </h3>
              </div>
              <span
                class="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs font-medium bg-blue-100 rounded-full"
              >
                Custom
              </span>
            </div>
            <!-- Description is generic and likely not useful here -->
            <!-- <p class="mt-1 text-sm text-gray-600">
              {{ customExam.description }}
            </p> -->
            <div class="flex flex-shrink-0 mt-4 text-sm text-gray-600">
              <svg
                class="w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span
                v-if="customExam.minutes"
                class="ml-2"
              >
                {{ customExam.minutes }} mins
              </span>
              <span
                v-else-if="!customExam.minutes"
                class="ml-2"
              >
                Not timed
              </span>
            </div>
          </div>
        </div>
        <div class="mt-auto">
          <div class="flex -mt-px divide-x divide-gray-200">
            <div
              v-if="useAddOption"
              class="flex flex-1 w-0 -ml-px"
            >
              <a
                v-if="canAddCustomExam(customExam)"
                href="javascript:;"
                class="relative inline-flex items-center justify-center flex-1 w-0 py-4 text-sm font-medium text-gray-700 border border-transparent rounded-br-lg hover:text-gray-500"
                @click="addCustomExam(customExam)"
              >
                <svg
                  class="w-5 h-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-2">Add</span>
              </a>
              <span
                v-else-if="!canAddCustomExam(customExam)"
                class="relative inline-flex items-center justify-center flex-1 w-0 py-4 text-sm font-medium text-gray-400 border border-transparent rounded-br-lg"
              >
                <svg
                  class="w-5 h-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-2">Add</span>
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div
      v-if="exams.length === 0 && customExams.length === 0"
      class="py-12 text-center bg-white"
    >
      <div v-if="searchQuery">
        <p>No tests could be found searching for “{{ searchQuery }}”</p>
      </div>
      <div v-else>
        <p>No tests could be found using this filter</p>
      </div>
    </div>

    <Modal
      v-if="examPreview"
      :open="modalOpen"
      size="max-w-screen-xl"
      @close="modalOpen = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <span class="inline-flex items-center space-x-2">
            <ExamIcon
              :icon="examPreview.icon"
              class="w-8 h-8"
            />
            <h3 class="text-2xl font-medium">
              {{ examPreview.name }}
            </h3>
          </span>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div class="grid py-6 divide-x sm:grid-cols-2">
        <div class="px-8">
          <p>{{ examPreview.description }}</p>

          <ul class="grid grid-cols-1 gap-5 my-8 sm:gap-6 lg:grid-cols-2">
            <li class="flex col-span-1 rounded shadow-sm">
              <div class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium border border-gray-500 rounded-l">
                <svg
                  class="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
                </svg>
              </div>
              <div class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-400 rounded-r">
                <div class="flex-1 px-4 py-2 text-sm truncate">
                  <p class="font-medium text-gray-900">
                    Category
                  </p>
                  <p class="text-gray-600">
                    {{ titleCase(examPreview.type) }}
                  </p>
                </div>
              </div>
            </li>

            <li class="flex col-span-1 rounded shadow-sm">
              <div class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium border border-gray-500 rounded-l">
                <svg
                  class="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-400 rounded-r">
                <div class="flex-1 px-4 py-2 text-sm truncate">
                  <p class="font-medium text-gray-900">
                    Time
                  </p>
                  <p class="text-gray-600">
                    <span v-if="examPreview.minutes">{{ examPreview.minutes }} minutes</span>
                    <span v-else>No limit</span>
                  </p>
                </div>
              </div>
            </li>

            <li class="flex col-span-1 rounded shadow-sm">
              <div class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium border border-gray-500 rounded-l">
                <svg
                  class="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </div>
              <div class="flex items-center justify-between flex-1 truncate border-t border-b border-r border-gray-400 rounded-r">
                <div class="flex-1 px-4 py-2 text-sm truncate">
                  <p class="font-medium text-gray-900">
                    Questions
                  </p>
                  <p class="text-gray-600">
                    {{ examPreview.questionCount }}
                  </p>
                </div>
              </div>
            </li>

            <li class="flex col-span-1 rounded shadow-sm">
              <div class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium border border-gray-500 rounded-l">
                <svg
                  class="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                </svg>
              </div>
              <div class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-400 rounded-r">
                <div class="flex-1 px-4 py-2 text-sm truncate">
                  <p class="font-medium text-gray-900">
                    Difficulty
                  </p>
                  <p class="text-gray-600">
                    {{ titleCase(examPreview.difficulty) }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <h4 class="mb-3 text-xl font-medium">
            Key competencies
          </h4>
          <p>{{ examPreview.keyCompetencies }}</p>
        </div>
        <div class="px-8">
          <div v-if="examPreview.relevantJobRolesHtml">
            <h4 class="mb-3 text-xl font-medium">
              Relevant job roles
            </h4>
            <div
              class="prose"
              v-html="examPreview.relevantJobRolesHtml"
            ></div>
          </div>

          <div v-if="examPreview.pageContent && examPreview.pageContent.pickedProBioHtml">
            <h4 class="mt-8 mb-3 text-xl font-medium">
              The test creator
            </h4>
            <!-- @TODO 2022-08-31 Add in if possible if data allows? -->
            <!-- <div class="relative flex items-center px-6 py-5 mb-4 space-x-3 bg-white border border-gray-400 rounded shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
              <div class="flex-shrink-0">
                <img
                  class="w-10 h-10 rounded-full"
                  :src="examPreview.pageContent.pickedProImage"
                  alt=""
                >
              </div>
              <div class="flex-1 min-w-0">
                <a
                  href="#"
                  class="focus:outline-none"
                >
                  <span
                    class="absolute inset-0"
                    aria-hidden="true"
                  ></span>
                  <p class="text-sm font-medium">
                    Mrs New Hire
                  </p>
                  <p class="text-sm text-gray-600 truncate">
                    Occupational Psychologist, Picked
                  </p>
                </a>
              </div>
            </div> -->
            <div v-html="examPreview.pageContent.pickedProBioHtml"></div>
          </div>
        </div>
      </div>
    </Modal>
  </BaseWrapper>
  <BaseWrapper v-else-if="state === states.LOADING">
    <BaseLoader />
    <!-- @TODO 2023-02-10 Remove when no longer needed. This is the old loading
    state that mimics the finished page. On 2023-02-09 Guy asked that the
    loading states be consistent across all pages, however because this
    page is different it might look a bit strange, so if we want it back
    again then keep this in for the time being. -->
    <!-- <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <li
        v-for="loadingBlock in 3"
        :key="`loadingBlock-${loadingBlock}`"
        class="flex flex-col col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow"
      >
        <div class="w-full p-6 space-y-3">
          <div class="w-full h-12 bg-gray-100">
            &nbsp;
          </div>
          <div class="w-full h-24 bg-gray-100">
            &nbsp;
          </div>
        </div>
      </li>
    </ul> -->
  </BaseWrapper>
</template>

<script>
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'
import ExamIcon from '@components/Icons/Exams/ExamIcon'
import Lock from '@components/Icons/Lock'
import Unlock from '@components/Icons/Unlock'

import TooltipButton from '@components/TooltipButton'

import { mapGetters } from 'vuex'
import { titleCase } from '@utils/titleCase'
import states from '@api/states'

export default {
  components: {
    Modal,
    Icon,
    Plus,
    ExamIcon,
    Lock,
    Unlock,
    TooltipButton
  },

  props: {
    assessmentExamSlugs: {
      type: Array,
      default: () => []
    },
    assessmentCustomExamSlugs: {
      type: Array,
      default: () => []
    },
    examsLimitReached: {
      type: Boolean,
      default: false
    },
    useAddOption: {
      type: Boolean,
      default: false
    },
    searchQuery: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      states,
      titleCase,

      // Currently under PAYG with trials all tests are allowed, so disable
      // the ‘Free’ or ‘Unlock’ tooltips.
      showTooltips: false,

      modalOpen: false,
      previewExamSlug: null
    }
  },

  computed: {
    ...mapGetters({
      state: 'clientExams/state',
      exams: 'clientExams/exams',
      hasAnySubscription: 'subscriptions/hasAnySubscription',
      customExams: 'clientExams/customExams',
      isPAYG: 'organisations/isPAYG',
      inTrial: 'organisations/inTrial',
      hasPaygUnlockedExams: 'organisations/hasPaygUnlockedExams',
      hasUnlimitedJobs: 'organisations/hasUnlimitedJobs'
    }),

    /**
     * @return {Object}
     */
    examPreview() {
      if (!this.exams) {
        return
      }
      return this.exams.find(exam => exam.slug === this.previewExamSlug)
    }
  },

  methods: {
    /**
     * Open the preview for an exam
     */
    openPreview(slug) {
      this.previewExamSlug = slug
      this.modalOpen = true
    },

    /**
     * Emit a request to add an exam
     *
     * @param {Object} exam
     */
    addExam(exam) {
      this.$emit('addExam', exam)
    },

    /**
     * Emit a request to add a custom exam
     *
     * @param {Object} exam
     */
    addCustomExam(exam) {
      this.$emit('addCustomExam', exam)
    },

    /**
     * Can we add the chosen exam?
     *
     * @param {Object} exam
     * @return {Boolean}
     */
    canAddExam(exam) {
      if (this.examsLimitReached) {
        // No exams can be added as the limit has been reached
        return false
      }

      if (this.assessmentExamSlugs.includes(exam.slug)) {
        // The user has already included this exam
        return false
      }

      if (exam.includeInFreePlan) {
        // If an exam is ‘free’ then it can always be added
        return true
      }

      if (this.hasUnlimitedJobs) {
        // Unlimited use is allowed all
        return true
      }

      if (this.hasPaygUnlockedExams) {
        // Unlimited use is allowed all
        return true
      }

      if (this.inTrial) {
        // Allowed all during a trial
        return true
      }

      if (this.hasAnySubscription) {
        // User has an active subscription so they’re allowed non-free exams
        return true
      }

      return false
    },

    /**
     * Can we add the chosen custom exam?
     *
     * @param {Object} exam
     * @return {Boolean}
     */
    canAddCustomExam(exam) {
      if (this.examsLimitReached) {
        // No exams can be added as the limit has been reached
        return false
      }

      if (this.assessmentCustomExamSlugs.includes(exam.slug)) {
        // The user has already included this exam
        return false
      }

      return true
    }
  }
}
</script>
