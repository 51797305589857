export const titleCase = str => {
  if (!str) {
    return str
  }
  if (str === str.toUpperCase()) {
    str = str.toLowerCase()
  }
  return str.split(' ').map((word) => {
    if (word.length === 0) {
      return word
    }
    if (word.toLowerCase() !== word) {
      return word
    }
    return word.replace(word[0], word[0].toUpperCase())
  }).join(' ')
}
